import { graphql, useStaticQuery } from 'gatsby'
import '@prismic/fragments/event'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { EventInterface, eventResolver } from '../prismic/lib/dataResolvers'

const useGetAllEvents = (count?: number): EventInterface[] => {
  const result = useStaticQuery(graphql`
    {
      allPrismicEvent {
        nodes {
          ...prismicEventFragmentFull
        }
      }
    }
  `)

  if (!result) return []
  const { language: selectedLang } = useLanguage()

  const news = result.allPrismicEvent.nodes
    .map((node: any) => eventResolver(node))
    .filter(
      (node: any) => node.lang.substring(0, 2) === selectedLang && node.from
    )
    .sort((a: EventInterface, b: EventInterface) => {
      // Sort by descending order of dates if date from is available
      if (!a.from) {
        return 1
      } else if (!b.from) {
        return 1
      }
      return new Date(b.from).getTime() - new Date(a.from).getTime()
    })
  if (count) {
    return news.slice(0, count)
  }
  return news
}

export { useGetAllEvents }
