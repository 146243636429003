import create from 'zustand'
type KeyType = { id: string; param: any }

interface ISessionStore {
  keys: KeyType[]
  updateKey: (k: KeyType) => void
  initKey: (k: KeyType) => void
}

const useSessionStore = create<ISessionStore>(set => ({
  keys: [],
  initKey: key =>
    set(prev => ({
      keys: [...prev.keys, key]
    })),
  updateKey: key => {
    set(prev => {
      const existing = prev.keys.find(k => k.id === key.id)
      if (existing) {
        let newParam = key.param + existing.param
        return {
          keys: [
            ...prev.keys.filter(k => k.id !== key.id),
            { id: key.id, param: newParam }
          ]
        }
      } else {
        return { keys: [...prev.keys, key] }
      }
    })
  }
}))

export { useSessionStore, ISessionStore }
