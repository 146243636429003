import {
  ThreeImagesProps,
  HeroMediaType,
  SEOProps,
  FeatureProps
} from '@islandsstofa/react'
import { PdfTableProps } from '@cmp/sections/PdfTable'
import {
  companyResolver,
  eventResolver,
  representativeResolver
} from '@prismic/lib/dataResolvers'
import { ArticleArchiveProps } from '@cmp/site/ArticleArchive'
import { CompanyCardGridProps } from '@cmp/sections/CompanyCardGrid'
import Img from 'gatsby-image'
import { handleImgixUrl } from '@kogk/common'
import React from 'react'

export type SliceComponentType =
  | 'rich_text'
  | 'three_images'
  | 'hero_media'
  | 'seo'
  | 'feature'
  | 'system'
  | 'company_card_grid'
  | 'event_archive'
  | 'pdf_table'
  | 'image_grid'

export type SliceType = {
  primary: any
  items: any[]
  slice_type: SliceComponentType
  id: string
}

const propResolver = (slice: SliceType, idx: number) => {
  switch (slice.slice_type) {
    case 'company_card_grid': {
      const props: CompanyCardGridProps = {
        title: slice.primary.grid_title.text,
        cards: slice.items
          .filter(item =>
            item.use_only_representative
              ? item.representative.document
              : item.company.document && item.representative.document
          )
          .map(item => ({
            company: item.company.document
              ? companyResolver(item.company.document)
              : undefined,
            representative: representativeResolver(
              item.representative.document
            ),
            tags: item?.tags
              ? item.tags.split(',').map((tag: string) => tag.trim())
              : [],
            useOnlyRep: !!item.use_only_representative
          }))
      }
      return props
    }
    case 'pdf_table': {
      const props: PdfTableProps = {
        title: slice.primary.title.text,
        description: slice.primary.description,
        pdfs: slice.items.map((item: any) => item)
      }
      return props
    }
    case 'event_archive': {
      const props: ArticleArchiveProps = {
        id: slice.id,
        limit: slice.primary.limit ?? 6,
        title: slice.primary.sliceTitle,
        useAllEvents: slice.primary.use_all_events,
        handpickedArticles: slice.items
          .filter(item => item?.event?.document)
          .map((item: any) => eventResolver(item.event.document))
      }
      return props
    }
    case 'system': {
      return {
        programName: slice.primary.program,
        options: slice.items
          .filter(
            ({ parameter, value }: { parameter: string; value: string }) =>
              parameter && value !== null
          )
          .reduce((acc: any, item: any) => {
            acc[item.parameter] = item.value
            return acc
          }, {})
      }
    }
    case 'feature': {
      const props: FeatureProps = {
        html: slice.primary.text.html,
        image: slice.primary.image,
        imageOnRight: slice.primary.image_on_right
      }
      return props
    }

    case 'rich_text': {
      return {
        html: slice.primary.rich_text.html
      }
    }
    case 'three_images': {
      const props: ThreeImagesProps = {
        images: [
          slice.primary.first_image,
          slice.primary.second_image,
          slice.primary.third_image
        ].filter(image => image.url),
        moreMargin: slice.primary.more_margin,
        onRenderImage: image => (
          <Img
            fluid={handleImgixUrl(image.url).gatsbyImageFluid()}
            alt={image.alt}
          />
        )
      }
      return props
    }
    case 'hero_media': {
      const props: HeroMediaType = {
        singleImage: slice.primary.single_image,
        video: slice.primary.video,
        caption: slice.primary.caption,
        firstOnPage: idx === 0,
        gallery: slice.items,
        onRenderImageComponent: () => (
          <Img
            fluid={slice.primary.single_image.fluid}
            alt={slice.primary.single_image.alt}
            className='image-rounded'
          />
        )
      }
      return props
    }
    case 'seo': {
      const props: SEOProps = {
        title: slice.primary.override_title,
        description: slice.primary.override_description,
        image: slice.primary.override_image,
        keywords: slice.primary.override_keywords
      }
      return props
    }
    case 'image_grid': {
      const props = {
        headline: slice.primary.headline.text,
        elements: slice.items.map((item: any) => ({
          logo: item.logo,
          name: item.name,
          url: item.website_url.url
        }))
      }
      return props
    }
    default:
      return {}
  }
}

export { propResolver }
