import React, { useEffect } from 'react'
import '@src/prismic/fragments/page'
import { EventInterface } from '@prismic/lib/dataResolvers'
import { ArticleFeature, ButtonDep as Button } from '@islandsstofa/react'
import { Fade } from 'react-reveal'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { useGetAllEvents } from '@root/src/hooks/useGetAllEvents'
import { useSessionStore } from '@root/src/store/sessionStore'

export type ArticleArchiveProps = {
  handpickedArticles: EventInterface[]
  title: string
  id: string
  useAllEvents: boolean
  limit?: number
}

const ArticleArchive = ({
  handpickedArticles,
  title,
  id,
  useAllEvents,
  limit
}: ArticleArchiveProps) => {
  const data = useGetAllEvents()

  if (!data) return null

  const events: EventInterface[] = useAllEvents ? data : handpickedArticles
  const { keys, updateKey, initKey } = useSessionStore()
  const initialLoad = limit ?? 6
  const currentKey = keys.find((k: { id: string }) => k.id === id)

  useEffect(() => {
    if (!currentKey) {
      initKey({ id, param: initialLoad })
    }
  }, [])

  return (
    <div className='article-archive container page-slice-spacer'>
      <h2 className='mt-3 mt-lg-0 mb-3'>{title ?? 'Events'}</h2>
      <div className='article-archive__grid'>
        {events.slice(0, currentKey?.param ?? initialLoad).map((a, i) => (
          <Fade duration={500} bottom distance={'5px'} key={i}>
            <Link to={a.url}>
              <ArticleFeature
                title={a.title}
                text={a.excerpt}
                image={a.featuredImage}
                tag={a.tags.length > 0 ? a.tags[0] : undefined}
                tagType='white'
                onRenderImage={() => (
                  <Img
                    className='h-100 w-100 image-rounded'
                    fluid={a.featuredImage.fluid}
                  />
                )}
              />
            </Link>
          </Fade>
        ))}
      </div>
      {currentKey?.param !== undefined && currentKey.param < events.length && (
        <Button
          onClick={() => updateKey({ id, param: initialLoad })}
          className='w-100'
          label='Load more'
          type='primary-inverted'
        />
      )}
    </div>
  )
}

export default ArticleArchive
