import { graphql } from 'gatsby'
import './company'
export const fragment = graphql`
  fragment prismicEventFragmentFull on PrismicEvent {
    url
    uid
    type
    prismicId
    lang
    tags
    alternate_languages {
      document {
        ... on PrismicEvent {
          url
          lang
        }
      }
    }
    data {
      title {
        text
      }
      from
      to
      time_from
      location
      featured_image {
        alt
        url
        fluid {
          ...GatsbyPrismicImageFluid
        }
      }
      excerpt {
        html
        text
      }
      body {
        ... on PrismicEventBodyRichText {
          ...sliceFragmentPrismicEventBodyRichText
        }
        ... on PrismicEventBodySystem {
          ...sliceFragmentPrismicEventBodySystem
        }
        ... on PrismicEventBodyHeroMedia {
          ...sliceFragmentPrismicEventBodyHeroMedia
        }
        ... on PrismicEventBodyThreeImages {
          ...sliceFragmentPrismicEventBodyThreeImages
        }
        ... on PrismicEventBodySeo {
          ...sliceFragmentPrismicEventBodySeo
        }
        ... on PrismicEventBodyPdfTable {
          ...sliceFragmentPrismicEventBodyPdfTable
        }
        ... on PrismicEventBodyCompanyCardGrid {
          ...companyCardGridSlice
        }
        ... on PrismicEventBodyImageGrid {
          ...sliceFragmentPrismicEventImageGrid
        }
      }
    }
  }

  fragment companyCardGridSlice on PrismicEventBodyCompanyCardGrid {
    id
    slice_type
    primary {
      grid_title {
        text
      }
    }
    items {
      company {
        document {
          ... on PrismicCompany {
            data {
              name {
                text
              }
              excerpt {
                html
              }
              about {
                html
              }
              website {
                url
              }
              image {
                url
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
      representative {
        document {
          ... on PrismicRepresentative {
            data {
              fullName: name {
                text
              }
              role
              phone
              email
              portrait {
                alt
                url
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              excerpt {
                html
              }
              about {
                html
              }
              website {
                url
              }
            }
          }
        }
      }
      tags
      use_only_representative
    }
  }

  fragment sliceFragmentPrismicEventBodyPdfTable on PrismicEventBodyPdfTable {
    id
    slice_type
    primary {
      title {
        text
      }
      description {
        html
      }
    }
    items {
      name
      description {
        html
      }
      link {
        url
      }
      downloadable
    }
  }

  fragment sliceFragmentPrismicEventBodySeo on PrismicEventBodySeo {
    id
    slice_type
    primary {
      override_title
      override_description
      override_keywords
      override_image {
        url
      }
    }
  }

  fragment sliceFragmentPrismicEventBodyThreeImages on PrismicEventBodyThreeImages {
    id
    slice_type
    primary {
      more_margin
      first_image {
        alt
        url
        fluid {
          ...GatsbyPrismicImageFluid
        }
      }
      second_image {
        alt
        url
        fluid {
          ...GatsbyPrismicImageFluid
        }
      }
      third_image {
        alt
        url
        fluid {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }

  fragment sliceFragmentPrismicEventBodyHeroMedia on PrismicEventBodyHeroMedia {
    id
    slice_type
    primary {
      caption
      see_gallery_message
      single_image {
        url
        alt
        fluid {
          ...GatsbyPrismicImageFluid
        }
      }
      video {
        url
      }
      smallVideo: small_video
    }
    items {
      gallery_image {
        url
      }
      image_title
      image_caption
    }
  }

  fragment sliceFragmentPrismicEventBodyRichText on PrismicEventBodyRichText {
    id
    slice_type
    primary {
      rich_text {
        html
        raw
      }
    }
  }

  fragment sliceFragmentPrismicEventBodySystem on PrismicEventBodySystem {
    id
    slice_type
    slice_label
    primary {
      program
    }
    items {
      parameter
      value
    }
  }

  fragment sliceFragmentPrismicEventImageGrid on PrismicEventBodyImageGrid {
    id
    slice_type
    primary {
      headline {
        text
      }
    }
    items {
      logo {
        url
        alt
      }
      website_url {
        url
      }
    }
  }
`
